<template>
  <b-card title="Dashboard Password">
    <b-modal
      id="promptUpdate"
      ref="modal"
      v-model="promptUpdate"
      title="Update Password"
      hide-footer
    >
    <b-form>
        <b-card-actions-container class="bv-edit-password">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label id="edit-dashboard-old-password" for="edit-dashboard-old-password">Old Password</label>
                <b-form-input
                  id="edit-dashboard-old-password-input"
                  v-model="oldPassword"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label id="edit-dashboard-new-password" for="edit-dashboard-new-password">New Password</label>
                <b-form-input
                  id="edit-dashboard-new-password-input"
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                />
                
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label id="edit-edit-show-password" for="edit-show-password">Show Password</label>
                <b-input-group-append is-text id="edit-show-password">
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-form-group>
            </b-col>
            
            <b-col cols="12">
              <!-- Solid divider -->
              <hr class="solid">
            </b-col>               
          </b-row>
        </b-card-actions-container>
        <div class="w-100">
          <b-button @click="updatePassowrd" class="float-right" variant="primary">Submit</b-button>  
        </div>
        <div class="w-100">
          <b-button @click="this.promptUpdate=false" class="float-right" variant="danger">Cancel</b-button>  
        </div>
      </b-form> 
      <template #modal-footer>
        <div class="w-100">    
        </div>
      </template>
    
      
    </b-modal>
    <b-row class="mb-2">

      <b-col cols="2">
        Last Updated
      </b-col>
      <b-col cols="10">
        {{ dates(dashboardPassword.updatedAt) }}
      </b-col>
      <b-col cols="2">
        Last Updated By
      </b-col>
      <b-col cols="10">
        {{ dashboardPassword.updatedBy }}
      </b-col>
      <b-col cols="12" class="mt-2">
        <b-button variant="success" @click="promptUpdate=true">Update</b-button>
      </b-col>
    </b-row>
    
  </b-card>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { userAccess } from "@/utils/utils";
import { dateFormat } from "@/utils/utils";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  mixins: [togglePasswordVisibility],
  data() {
    return {
      promptUpdate:false,
      oldPassword:"",
      password:"",
    };
  },
  mounted() {
    this.$store.dispatch("dashboard/getDashboardPassword");
  },
  methods: {
    dates(date) {
      return dateFormat(date);
    },
    updatePassowrd(){
      this.$store.dispatch('dashboard/updateDashboardPassword',{
        oldPassword:this.oldPassword,
        password:this.password
      }).then((res)=>{
        this.$bvToast.toast('Update Successful', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
        this.promptUpdate=false
      }).catch((err)=>{
        console.error(err)
        var message = (err.response.data.errors[0].message)?err.response.data.errors[0].message:""
        
        this.$bvToast.toast('Update Failed - '+message, {
          title: 'Failed',
          variant: 'danger',
          solid: true,
        })
      })
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    permission() {
      console.log("userAccess", userAccess("Dashboard Password", "settings_menu"));
      return userAccess("Dashboard Password", "settings_menu");
    },
    dashboardPassword() {
      console.info(this.$store.getters["dashboard/getDashboardPassword"])
      return this.$store.getters["dashboard/getDashboardPassword"];
    },
  },
  created() {
    document.title = "Dashboard Password | RSP";
  },
};
</script>
